<template>
  <div class="text">
    <p>
      The LEGO Universe team had a very busy but fantastic time at LEGO World in Zwolle, Netherlands, from the 22nd to 27th October. Nearly 60,000 people attended the event over the 6 days, and loads of fans visited the LEGO Universe stand! Children were asked to build something they would like to see in the game -- check out some of their excellent creations below! The response from children and parents alike was overwhelming, and much fun was had by all!
    </p>
    <!-- MISSING: pic748B6356EEC35A4A82DB5707ADA79522.jpg -->
    <img class="rounded" src="">
    <!-- MISSING: picAF2C4C87CEC506BD60A5999726F2C662.jpg -->
    <img class="rounded" src="">
    <!-- MISSING: picBCFEC0AD85494C6F308B0E698311CAF0.jpg -->
    <img class="rounded" src="">
    <!-- MISSING: picFDF0B162011A856D5BFF75E531D9C856.jpg -->
    <img class="rounded" src="">
    <!-- MISSING: picC3F5901CBE9179116A2665D323578BB4.jpg -->
    <img class="rounded" src="">
    <!-- MISSING: picAEB6ECEB382644B4511CC05BF5049CEB.jpg -->
    <img class="rounded" src="">
  </div>
</template>
